import React from 'react';

import ViewContainer from '../../../view';
// import './ScanChooseStore.module.css';
import BaseComponent from '../../../base';
import DataGrid from '../../../components/datagrid/DataGrid';
import Backend from '../../../services/Backend';

import { scanTablStyle } from '../../../TableGridCssOverride';
import classes from './ScanChooseStore.module.css';
class ScanChooseStore extends BaseComponent {
	constructor(props) {
		super(props);

		Backend.fetchButiker().then((stores) => {
			this.setState({
				loading: false,
				stores: stores,
			});
		});
	}

	state = {
		loading: true,
		stores: [],
		columns: [
			{
				id: 'namn',
				name: 'Namn',
				sortable: true,
				selector: (row) => row.namn,
			},
		],
	};

	onRowClick(store) {
		this.setState({ storeId: store._key });
		if (this.props.fromFile) {
			this.redirect(`/scan/fromFile/${store._key}/import`);
		} else {
			this.redirect(`/scan/${store._key}/search`);
		}
	}

	render() {
		return (
			<ViewContainer
				title="Butiker"
				className={classes.storeList}
			>
				<p>{this.props.fromFile ? 'Välj butik för scanning från fil' : 'Välj butik för manuell scanning'}</p>
				<DataGrid
					columns={this.state.columns}
					data={this.state.stores}
					onClick={this.onRowClick.bind(this)}
					defaultSortFieldId={'namn'}
					sortDirection={'asc'}
					customStyles={scanTablStyle}
					loading={this.state.loading}
				></DataGrid>
			</ViewContainer>
		);
	}
}

export default ScanChooseStore;
