import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import LoadingContainer from '../loading/LoadingContainer';
import FilterComponent from './FilterComponent';

const customSort = (rows, selector, direction) => {
	return rows.sort((rowA, rowB) => {
		// use the selector function to resolve your field names by passing the sort comparitors
		const aField = selector(rowA);
		const bField = selector(rowB);

		let comparison = 0;

		if (aField > bField) {
			comparison = 1;
		} else if (aField < bField) {
			comparison = -1;
		}

		return direction === 'desc' ? comparison * -1 : comparison;
	});
};

// Todo: Fixa filtrering

export default function DataGrid(props) {
	const [data, setData] = useState(props.data);
	const [columns, setColumns] = useState(props.columns);
	const [showPagination, setShowPagination] = useState(props.showPagination == null ? true : props.showPagination);
	const [tableHeight, setTableHeight] = useState(
		props.tableHeight == undefined ? props.customStyles.table.style.minHeight : props.tableHeight,
	);
	const [conditionalRowStyles, setConditionalRowStyles] = useState(props.conditionalRowStyles);
	const [sortDirection, setSortDirection] = useState(props.sortDirection == null ? 'asc' : props.sortDirection);
	const [loading, setLoading] = useState(props.loading);
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [filterKey, setFilterKey] = useState(props.filterKey);
	const [title, setTitle] = useState(props.title);
	const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);
	const [rowsPaginationPerPage, setRowsPaginationPerPage] = useState(props.defaultRowsPerPage || 50);
	const [defaultKey, _] = useState(props.defaultKey || 'id');

	const paginationComponentOptions = {
		rowsPerPageText: 'Rader per sida',
		rangeSeparatorText: 'av',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Välj alla',
	};

	const itemFilterHit = (item) => {
		return Object.keys(item).some((key) => {
			return item[key] !== null && item[key] !== undefined && typeof item[key] === 'object'
				? itemFilterHit(item[key])
				: (item[key] || '').toString().toLowerCase().includes(filterText.toLowerCase());
		});
	};

	const filteredItems = data.filter((item) => filterText === '' || itemFilterHit(item));

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				//  setResetPaginationToggle(!resetPaginationToggle);
				setFilterText();
			}
		};

		return (
			<>
				<FilterComponent
					onFilter={(e) => setFilterText(e.target.value)}
					onClear={handleClear}
					filterText={filterText}
				/>
			</>
		);
	}, [filterText, resetPaginationToggle]);

	const getArrayWithDivisor = (num, div) => {
		return Array(Math.floor(num / div))
			.fill(undefined)
			.map((el, i) => (i + 1) * div);
	};

	const getInitDiv = (num, x = 50) => {
		return num < x ? x : getInitDiv(num, x * 50);
	};

	const _getDropDownOptionsFromRows = (num, initDiv) =>
		initDiv >= 50
			? _getDropDownOptionsFromRows(initDiv / 10 > num ? num : initDiv / 10, initDiv / 50).concat(
					getArrayWithDivisor(num, initDiv),
			  )
			: [];

	useEffect(() => {
		setData(props.data);
		if (data && data.length > 0) {
			const rowsPerPage = _getDropDownOptionsFromRows(props.data.length, getInitDiv(props.data.length));
			setRowsPerPage(rowsPerPage);
		}
		setLoading(props.loading);
	}, [props.loading, props.data, data]);

	// Todo: FULHACK med filter av o på

	return (
		<Box
			component="div"
			sx={{ marginTop: '2rem', width: '100%', height: '100%' }}
		>
			<DataTable
				keyField={defaultKey}
				data={filteredItems}
				columns={props.columns}
				noHeader={title === '' ? true : false}
				title={title}
				fixedHeader
				fixedHeaderScrollHeight={tableHeight}
				pagination={showPagination}
				striped
				highlightOnHover={true}
				pointerOnHover={true}
				onRowClicked={props.onClick}
				paginationComponentOptions={paginationComponentOptions}
				paginationRowsPerPageOptions={rowsPerPage}
				paginationPerPage={rowsPaginationPerPage}
				onChangePage={(page, totalrows) => {
					if (props.onChangePage !== undefined) {
						props.onChangePage(page, totalrows);
					}
				}}
				sortFunction={customSort}
				defaultSortFieldId={props.defaultSortFieldId}
				defaultSortAsc={sortDirection === 'asc'}
				subHeaderAlign="left"
				sortDirection={sortDirection}
				subHeader={title ? true : false}
				subHeaderWrap
				noDataComponent={
					<div>
						<Typography
							variant="h6"
							gutterBottom
						>
							Inga resultat
						</Typography>
					</div>
				}
				conditionalRowStyles={conditionalRowStyles}
				customStyles={props.customStyles}
				progressPending={loading}
				subHeaderComponent={filterKey && filterKey.length > 0 ? subHeaderComponentMemo : null}
				progressComponent={
					<LoadingContainer
						enabled={loading}
						customSyles={props.customStyles}
					/>
				}
			/>
		</Box>
	);
}
