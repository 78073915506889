import React from 'react';
import BackendMPK2 from './BackendMPK2';

export default class Backend extends React.Component {
	static getBaseURL() {
		return BackendMPK2.getBaseURL();
	}

	static fetchBrands() {
		return BackendMPK2.get('varumarke/namn');
	}

	static fetchDistributors() {
		return BackendMPK2.get('leverantor');
	}

	static createNewDistributor(payload) {
		return BackendMPK2.post('leverantor', payload);
	}

	static updateDistributor(payload) {
		return BackendMPK2.put('leverantor', payload);
	}

	static fetchPrices(productId) {
		const urlPrefix = 'product/';
		const urlSuffix = '/prices';
		return BackendMPK2.get(urlPrefix + productId + urlSuffix);
	}

	static fetchProduct(key) {
		return BackendMPK2.get(`produkt/${key}`);
	}

	static fetchPotentialDuplicates() {
		return BackendMPK2.get('produkt/potentialduplicates');
	}

	static setNotADuplicateProducts(keys) {
		var query = `produktKey=${keys[0]}`;
		keys.slice(1).forEach((k) => {
			query += `&produktKey=${k}`;
		});
		return BackendMPK2.put(`produkt/not-a-duplicate?${query}`, keys);
	}

	static gtinExists(gtin) {
		return BackendMPK2.get(`produkt/gtinexists/${gtin}`);
	}

	static fetchSearchResults(
		searchText,
		manufacturer,
		categoryId,
		matchedProductIds,
		chain,
		includeInvisible,
		page,
		sortProperty,
		sortDescending,
		onlyWithoutProduktGrupp,
		storeKey,
		includeNonPublicCombined,
	) {
		const urlPrefix = 'produkt/search?q=';
		const urlSuffix = '&amount=20&page=' + page;
		let url = urlPrefix + encodeURI(searchText) + urlSuffix;
		if (manufacturer) {
			url += '&manufacturer=' + encodeURI(manufacturer);
		}
		if (categoryId) {
			url += '&categoryId=' + encodeURI(categoryId);
		}
		if (includeInvisible) {
			url += '&includeInvisible=' + encodeURI(true);
		}
		if (includeNonPublicCombined) {
			url += '&includeNonPublicCombined=' + encodeURI(true);
		}
		if (matchedProductIds) {
			matchedProductIds.forEach((x) => {
				url += '&matchIds=' + encodeURI(x);
			});
		}
		if (chain !== null && !isNaN(chain) && chain >= 0) {
			url += '&chain=' + encodeURI(chain);
		}

		if (!isNaN(sortProperty) && sortProperty >= 0) {
			url += '&sortField=' + encodeURI(sortProperty);

			if (sortDescending === 1) {
				url += '&sortDescending=true';
			}
		}

		if (onlyWithoutProduktGrupp) {
			url += '&onlyWithoutProduktGrupp=true';
		}

		if (storeKey) {
			url += '&hasActivePriceInButikKey=' + storeKey;
		}

		url += '&includeSpindlingsDatum=true';
		return BackendMPK2.get(url);
	}

	static fetchSkynetSearchResults(searchText, amount, price, em) {
		const url = 'AI/kombinerade';
		const test = {
			produkt: searchText,
			mangd: amount,
			pris: price,
			em: em,
		};

		return BackendMPK2.post(url, test);
	}

	static sendFeedback(gtins) {
		return BackendMPK2.post('AI/feedback', gtins);
	}

	static getCategoryProducts(categoryId) {
		const url = 'product/search?categoryId=' + encodeURI(categoryId);
		return BackendMPK2.get(url);
	}

	static fetchChainNames() {
		// might have to adjust...
		return BackendMPK2.get('kedja');
	}

	static fetchChains() {
		return BackendMPK2.get('kedja');
	}

	static fetchBlock() {
		return BackendMPK2.get('block');
	}

	static fetchTypes() {
		return BackendMPK2.get('store/types');
	}

	static fetchCategory(id) {
		return BackendMPK2.get(`kategori/${id}`);
	}

	static fetchCategories(flatten = false) {
		return BackendMPK2.get(`category?flatten=${!!flatten}`);
	}

	static fetchCategoryDocuments() {
		return BackendMPK2.get('kategori');
	}

	static fetchKategoriTrad(includeProduktGrupp = true) {
		return BackendMPK2.get(`kategori/kategoritrad?includeproduktgrupp=${!!includeProduktGrupp}&includeEjSynlig=true`);
	}

	static fetchCategoriesWithParent(noCount = true) {
		return BackendMPK2.get(`kategori/withforalderandcount?noCount=${!!noCount}`);
	}

	static fetchCategoryCount() {
		return BackendMPK2.get('category/count');
	}

	static fetchCategoriesByProdukt(produktKey) {
		return BackendMPK2.get(`produkt/${produktKey}/kategori`);
	}

	static getUnmatchedByCatProducts(page) {
		const urlSuffix = `?&amount=20&page=${page}`;

		const url = 'product/unmatched' + urlSuffix;
		return BackendMPK2.get(url);
	}

	static fetchProductsExcel(productBagKey, downloadFilename) {
		return BackendMPK2.download_file('ProduktSamling/' + productBagKey + '/export/xlsx', downloadFilename);
	}

	static getUnmatchedProducts(
		page,
		showGtin,
		store,
		notStore,
		notCats,
		manufacturer,
		lastSeenFirst,
		showHidden,
		only2Weeks,
		name,
		crawledIn30Days,
	) {
		let params = `?&antal=20&sida=${page}`;
		if (showGtin) {
			params += `&gtin=${encodeURI(showGtin)}`;
		}
		if (lastSeenFirst) {
			params += `&sorteraLastSeen=${lastSeenFirst}`;
		}
		if (showHidden) {
			params += `&enbartDolda=${showHidden}`;
		}
		if (only2Weeks) {
			params += `&enbartSenaste2Veckorna=${only2Weeks}`;
		}
		if (crawledIn30Days) {
			params += `&SpindladSenastMaxDagar=30`;
		}
		if (manufacturer) {
			params += `&varumarke=${manufacturer}`;
		}
		if (name) {
			params += `&fritext=${name}`;
		}
		if (store) {
			params += `&butik=${store}`;
		}
		if (notStore) {
			params += `&exkluderadButik=${notStore}`;
		}
		if (notCats) {
			notCats.forEach((x) => {
				params += `&ExkluderaKategorier=${x}`;
			});
			console.log('%c⧭', 'color: #40fff2', params);
		}

		const url = 'butikprodukt/unmatched' + params;
		return BackendMPK2.get(url);
	}

	static unmatch(crawlProductId) {
		return BackendMPK2.put(`butikprodukt/${crawlProductId}/unmatch`, {});
	}

	static fetchVarumarken() {
		return BackendMPK2.get('varumarke');
	}

	static fetchVarumarke(key) {
		return BackendMPK2.get(`produkt/Varumarke/${key}`);
	}
	static fetchDistributorByBrandKey(key) {
		return BackendMPK2.get(`produkt/leverantor/varumarke/${key}`);
	}
	static fetchDistributorByKey(key) {
		return BackendMPK2.get(`produkt/leverantor/${key}`);
	}

	static fetchStore(id) {
		return new Promise((resolve, reject) => {
			BackendMPK2.get(`butik/${id}/withblockandkedja`).then((b) => {
				b.butik.namn = `${b.kedja.namn} ${b.butik.namn}`;
				resolve(b);
			}, reject);
		});
	}

	static fetchStores() {
		return BackendMPK2.get('butik/withblockandkedja');
	}

	static fetchButiker() {
		return new Promise((resolve, reject) => {
			BackendMPK2.get('butik/withblockandkedja').then((bs) => {
				const butiker = bs.map((b) => {
					b.butik.namn = `${b.kedja.namn} ${b.butik.namn}`;
					return b.butik;
				});
				resolve(butiker);
			}, reject);
		});
	}

	static fetchStats() {
		return BackendMPK2.getStatistik();
	}

	static fetchProductCrawlProducts(key, includeAll) {
		return BackendMPK2.get(`produkt/${key}/butikprodukter?all=${!!includeAll}`);
	}

	static fetchProductType(id) {
		return BackendMPK2.get(`product/${id}/type`);
	}

	static fetchParentProducts(key) {
		return BackendMPK2.get(`produkt/${key}/parents`);
	}

	static fetchCombinedProducts(key) {
		return BackendMPK2.get(`produkt/${key}/combined`);
	}

	static fetchCombinedProductMissingPricesStores(key) {
		return BackendMPK2.get(`produkt/combined/${key}/missingprices`);
	}

	static fetchCrawlProduct(key) {
		return BackendMPK2.get(`butikprodukt/${key}`);
	}

	static fetchCrawlProductCategories(key) {
		return BackendMPK2.get(`butikprodukt/${key}/kategori`);
	}

	static fetchCrawlers() {
		return BackendMPK2.get('spindel');
	}

	static fetchCrawlerCategory(key) {
		return BackendMPK2.get(`ButikKategori/${key}/withkategori`);
	}

	static fetchCrawlerCategories(spindelKey) {
		return BackendMPK2.get(`ButikKategori/withkategoribyspindel/${spindelKey}`);
	}

	static fetchProductPrice(productId) {
		const dateNow = new Date().toISOString().substring(0, 10).replaceAll('-', '');
		const dateWayBack = '20160101'; // todo remove
		return BackendMPK2.get(`pris/${productId}/priser?from=${dateWayBack}&to=${dateNow}`);
	}

	static fetchProduktVarumarke(produktKey) {
		return BackendMPK2.get(`produkt/${produktKey}/varumarke`);
	}

	static fetchCompareStores(store1Id, store2Id) {
		return new Promise((resolve, reject) => {
			const url = `${BackendMPK2.getBaseURL()}butik/${store1Id}/compare/${store2Id}`;
			const token = BackendMPK2.getToken();
			const headers = {};

			if (token) {
				headers.Authorization = `Bearer ${token}`;
			}

			fetch(url, { headers })
				.then((response) => {
					if (!response.ok) {
						return reject();
					}

					return response.blob();
				}, reject)
				.then((data) => {
					resolve(data);
				}, reject);
		});
	}

	static fetchCrawls(amount, page) {
		return BackendMPK2.fetchCrawls(amount, page);
	}

	static fetchCrawl(id) {
		return BackendMPK2.get(`spindling/${id}/status`);
	}

	static fetchCrawlStores(id) {
		return BackendMPK2.get(`spindling/${id}/status/butiker`);
	}

	static fetchValidooProduct(gtin) {
		return BackendMPK2.get(`produkt/validoo?gtin=${gtin}`);
	}

	static fetchValidooImage(key) {
		return BackendMPK2.post(`produkt/${key}/update-validoo-image`);
	}

	static setCrawlProductHidden(productId, value) {
		return BackendMPK2.put(`butikprodukt/${productId}/synlig`, JSON.stringify(!!value));
	}

	static createProdukt(product) {
		const url = 'produkt';
		return BackendMPK2.post(url, product);
	}

	static saveCrawlerCategoryCategory(crawlerCategoryKey, categoryKey) {
		if (!categoryKey) {
			return BackendMPK2.put(`butikkategori/${crawlerCategoryKey}/kategori`); // remove mapping
		}
		return BackendMPK2.put(`butikkategori/${crawlerCategoryKey}/kategori`, categoryKey);
	}

	static saveCrawlProduct(key, product) {
		const url = `butikProdukt/${key}/produkt`;
		return BackendMPK2.put(url, product);
	}

	static matchMultipleCrawlProducts(produktKey, butikProduktKeys) {
		let url = `butikprodukt/multi/produkt/${produktKey}`;
		var query = butikProduktKeys.reduce(
			(acc, curr) => acc + (acc.length > 0 ? '&' : '?') + `butikProduktKeys=${curr}`,
			'',
		);

		return BackendMPK2.post(url + query, null);
	}

	static saveCombineProduct(key, product) {
		if (!key) {
			return BackendMPK2.post('produkt/combine', product);
		}

		return BackendMPK2.put(`produkt/combine/${key}`, product);
	}

	static saveProduct(key, product) {
		if (!key) {
			return BackendMPK2.post('product', product);
		}

		return BackendMPK2.put(`produkt/${key}`, product);
	}

	static deleteProduct(key) {
		return BackendMPK2.delete(`produkt/${key}`);
	}

	static deleteCombineProduct(id) {
		return BackendMPK2.delete(`produkt/combine/${id}`);
	}

	static mergeMatchedProduct(id) {
		return BackendMPK2.post(`produkt/${id}/merge-matched`, null);
	}

	static saveStore(payload) {
		if (payload.butik._key) {
			return BackendMPK2.put('butik/withblockandkedja', payload);
		}

		return BackendMPK2.post('butik/withblockandkedja', payload);
	}

	static deleteStore(id) {
		return BackendMPK2.delete(`butik/${id}`);
	}

	static saveCategory(category) {
		if (category.Key) {
			return BackendMPK2.put(`kategori/${category.Key}`, category);
		}

		return BackendMPK2.post('kategori', category);
	}

	static updateCategoryParent(categoryKey, newParent) {
		return BackendMPK2.put(`kategori/${categoryKey}/foralder`, newParent || '00000000-0000-0000-0000-000000000000');
	}

	static saveProductPrice(storeId, productId, price) {
		return BackendMPK2.post(`import/manualScan/butik/${storeId}/produkt/${productId}`, price);
	}

	static updateElasticSearchProducts() {
		return BackendMPK2.get('product/elasticsearch');
	}

	static fetchClients() {
		return BackendMPK2.get('klient');
	}

	static fetchClient(key) {
		return BackendMPK2.get(`klient/${key}`);
	}

	static saveClient(client) {
		if (client._key) {
			return BackendMPK2.put(`klient/${client._key}`, client);
		}
		return BackendMPK2.post('klient', client);
	}

	static deleteClient(key) {
		return BackendMPK2.delete(`klient/${key}`);
	}

	static fetchProductBags() {
		return BackendMPK2.get('produktsamling/withklient');
	}

	static fetchProductBag(key) {
		return BackendMPK2.get(`produktsamling/${key}`);
	}

	static fetchProductBagWithKlient(key) {
		return BackendMPK2.get(`produktsamling/${key}/withklient`);
	}

	static fetchProductBagProducts(key) {
		return BackendMPK2.get(`produktsamling/${key}/produkter`);
	}

	static saveProductBagProducts(id, productsArray) {
		return BackendMPK2.put(`produktsamling/${id}/produkter`, productsArray);
	}

	static triggerProductBagExport(key) {
		return BackendMPK2.post(`produktsamling/${key}/trigger-export`);
	}

	static saveProductBag(productBagObj) {
		if (!productBagObj._key) {
			return Backend.createNewProductBag(productBagObj);
		}
		return BackendMPK2.put(`produktsamling/${productBagObj._key}`, productBagObj);
	}

	static deleteProductBag(key) {
		return BackendMPK2.delete(`produktsamling/${key}`);
	}

	static createNewProductBag(data) {
		return BackendMPK2.post('produktsamling', data);
	}

	static getProductBagScriptProgress() {
		return BackendMPK2.get('productbag/script/progress');
	}

	static startProductBagScript(productBagId) {
		if (productBagId) {
			return BackendMPK2.get(`productbag/script/start?productBagId=${productBagId}`);
		}
		return BackendMPK2.get('productbag/script/start');
	}

	static uploadImportFilePreview(payload) {
		return BackendMPK2.post('import/excel/preview', payload);
	}

	static uploadImportFileExecute(payload) {
		return BackendMPK2.post('import/excel/import', payload);
	}

	static getJobStatus(jobId) {
		return BackendMPK2.get(`job/${jobId}/status`);
	}

	static updateMultipleVarumarkenLeverantor(payload) {
		return BackendMPK2.patch('Varumarke/Leverantor/varumarke/multipleLeverantorer', payload);
	}

	static createNewVarumarke(payload) {
		return BackendMPK2.post('Varumarke', payload);
	}

	static updateVarumarke(payload) {
		return BackendMPK2.put('Varumarke', payload);
	}

	static updateVarumarkeLeverantor(varumarkeKey, leverantorKey) {
		return BackendMPK2.patch(`Varumarke/${varumarkeKey}/leverantor/${leverantorKey}`);
	}

	static fetchVarumarkenWithLeverantorer() {
		return BackendMPK2.get('Varumarke/varumarkenWithLeverantorer');
	}

	static fetchVariationerWithVarumarken() {
		return BackendMPK2.get('Varumarke/varumarkevarianterWithParent');
	}

	static fetchMasterBrandList() {
		// return BackendMPK2.get('Varumarke/hardcodedMaster');
		return BackendMPK2.get('Varumarke/varumarkeMasterList');
	}

	static setParentForVariant(payload) {
		return BackendMPK2.put('Varumarke/setParentForVarumarken', payload);
	}

	static fetchEgenskaper() {
		return BackendMPK2.get('Egenskap');
	}

	static fetchEgenskaperByProduktKey(produktKey) {
		return BackendMPK2.get(`produkt/${produktKey}/egenskaper`);
	}

	static fetchUnverifiedProducts(page, amount, fromDate) {
		return BackendMPK2.get(`Produkt/unverified?page=${page}&amount=${amount}&fromDate=${fromDate}`);
	}

	static fetchCombinedProductsList() {
		return BackendMPK2.get(`Produkt/combined`);
	}

	static verifyProduct(productKey) {
		return BackendMPK2.post(`Produkt/${productKey}/verify`);
	}

	static verifyProducts(productKeys) {
		return BackendMPK2.post('Produkt/verify/multiple', productKeys);
	}

	static putProductProperty(productKey, propertyName, value) {
		return BackendMPK2.put(`Produkt/${productKey}/${propertyName}`, value);
	}

	static uploadImageToProduct(produktKey, fileName, base64) {
		return BackendMPK2.post(`Produkt/${produktKey}/upload-image`, {
			fileName,
			base64,
		});
	}

	static deleteProductImage(productKey) {
		return BackendMPK2.delete(`Produkt/${productKey}/image`);
	}

	static fetchProductbagStores(productbagKey) {
		return BackendMPK2.get(`produktsamling/${productbagKey}/butiker`);
	}

	static setProductbagStores(productbagKey, storeKeys) {
		return BackendMPK2.put(`produktsamling/${productbagKey}/butiker`, storeKeys);
	}

	static fetchProductbagScheduleTypes() {
		return BackendMPK2.get(`produktsamling/ExportSchemaTyper`);
	}

	static fetchIcaStoresJSon() {
		return BackendMPK2.getIcaStoresRequest(`https://handla.ica.se/api/store/v1?customerType=B2C`);
	}
}
