import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import React from 'react';

import { useParams } from 'react-router';
import BaseComponent from '../../../base';
import ProduktGruppChips from '../../../components/categories/ProduktGruppChips';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import MarksList from '../../../components/marks/MarksList';
import AiResultList from '../../../components/result/AiResultList';
import ResultList from '../../../components/result/ResultList';
import Backend from '../../../services/Backend';
import Utils from '../../../services/Utils';
import ViewContainer from '../../../view';

import {
	Card,
	Chip,
	CircularProgress,
	DialogActions,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import classes from './ProductCombine.module.css';
const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class ProductCombine extends BaseComponent {
	static validationRules = {
		namn: (product) => product.namn,
		categories: (_) => null,
		// 'categories': product => product.categories && product.categories.length > 0,
		// 'numberOfItems': product => !isNaN(product.numberOfItems) && product.numberOfItems > 0,
		// 'amountOfUnits': product => !isNaN(product.amountOfUnits) && product.amountOfUnits > 0,
	};

	constructor(props) {
		super(props);
		this.debounceTime = 300;

		Backend.fetchBlock().then((chainNames) => {
			const blockNames = chainNames.map((cn) => cn.namn);
			this.setState({
				chains: chainNames,
			});
		});
	}

	componentDidMount() {
		Backend.fetchCategoriesWithParent().then((categories) => {
			this.setState({
				categories,
			});
		});

		if (this.props.params.id) {
			Backend.fetchCombinedProducts(this.props.params.id).then((products) => {
				this.setState({
					originalProducts: products.map((p) => {
						p._key = p.key;
						return {
							Produkt: p,
							Varumarken: [{ namn: p.varumarke || '' }],
							SenastSpindlad: p.senastSpindlad,
						};
					}),
				});
			});

			Backend.fetchProductCrawlProducts(this.props.params.id, false).then((crawlproducts) => {
				crawlproducts.forEach((x) => {
					let matchedStore = this.stores.filter((xx) => xx.id == x.storeId)[0];
					if (matchedStore != null) {
						x.extra = matchedStore.name;
					}
				});

				this.setState({
					matchedProducts: crawlproducts || [],
					unmatchedProducts: [],
				});
			});

			let promise = new Promise((resolve) => resolve({}));
			promise = Backend.fetchProduct(this.props.params.id);
			promise.then(
				(product) => {
					Backend.fetchProduktVarumarke(product.key).then((varumarke) => {
						this.setState({
							varumarke,
							editVarumarke: varumarke.namn,
						});
					});

					Backend.fetchCategoriesByProdukt(product.key).then((cats) => {
						this.setState({
							editCategories: cats.map((c) => c._key),
						});
					});

					Backend.fetchEgenskaperByProduktKey(product.key).then((marks) => {
						this.setState({
							selectedMarks: marks.map((x) => x.key),
						});
					});

					this.setState({
						choosenUnit: Utils.getUnitName(product.unit),
						combineProduct: product,
						loading: false,
					});
				},
				(err) => {
					console.info(err);
				},
			);
		} else {
			this.setState({
				loading: false,
			});
		}
	}

	state = {
		categories: [],
		combineProduct: { synlig: true },
		choosenUnit: '',
		matchAlternatives: [],
		matchAlternativesSearch: '',
		matchAlternativesManufacturer: null,
		matchChain: 0,
		matchStore: null,
		matchCategoryKey: '',
		allowEmptyManufacturer: true,
		originalProducts: [],
		snackbar: '',
		productdeleted: false,
		deletesnackbar: '',
		deleteconfirmeddialog: '',
		savingProduct: false,
		saveButtonText: 'Spara',
		loading: true,
		loadingMissingPricesStores: false,
		missingPricesStores: [],
		page: 0,
		allResultsFetched: false,
		allAiResultsFetched: true,
		resultProgressVisible: false,
		unmatchedProducts: [],
		matchedProducts: [],
		chains: [],
		selectedMarks: [],
		uploadImage: null,
		uploadTime: 0,
		aiResultProgressVisible: false,
		aiMatchAlternatives: [],
		matchAiAlternativesSearch: '',
		aiResults: {
			combined: [],
			similar: [],
		},
		em: false,
		amount: 0,
		price: 0,
	};

	validateField(fieldName) {
		if (fieldName === 'categories') {
			return this.state.editCategories && this.state.editCategories.length > 0;
		}
		const product = this.state.combineProduct;
		const allowEmptyManufacturer = this.state.allowEmptyManufacturer;
		const func =
			ProductCombine.validationRules[fieldName] ||
			(() => {
				// empty function
			});
		return func(product, allowEmptyManufacturer);
	}

	validateUnitField() {
		const unit = Utils.getUnit(this.state.choosenUnit);
		return unit !== null && unit.length === 2;
	}
	validateOriginalProducts() {
		// These rules seems arbirtrary and are probably very clever, because it makes sense that a
		// duplicate product should have more than one original product. But that's not the reality,
		// so for now we'll just let this be OK, and try to fix the data instead.
		return this.state.originalProducts.length > 0; // this.state.originalProducts.length > 1;
	}

	isFieldsValid() {
		return (
			/* this.validateUnitField() && */ this.validateOriginalProducts() &&
			Object.keys(ProductCombine.validationRules).every(this.validateField.bind(this))
		);
	}

	fetchWithSkynet(searchText, amount, price, em) {
		this.setState(
			{
				matchAiAlternativesSearch: searchText,
				amount: amount,
				price: price,
				em: em,
				aiResultProgressVisible: true,
			},
			() => {
				if (searchText || amount || price || em) {
					Backend.fetchSkynetSearchResults(searchText, amount, price, em).then((result) => {
						if (result && result['similar'].length == 0 && result['combined'].length == 0) {
							this.setState({
								allAiResultsFetched: true,
								aiResultProgressVisible: false,
							});
						} else {
							this.setState({
								allAiResultsFetched: true,
								aiResultProgressVisible: false,
								aiResults: result,
							});
						}
					});
				} else {
					this.setState({
						allAiResultsFetched: true,
						aiResultProgressVisible: false,
					});
				}
			},
		);
	}

	fetchAlternatives(searchText, searchManufacturer, searchChain, searchStore, searchCategory, page) {
		var matchedProductsIds = this.state.originalProducts.map((x) => x.Produkt._key);
		const chainChanged = searchChain != this.state.matchChain;
		this.setState(
			{
				matchAlternativesSearch: searchText,
				matchAlternativesManufacturer: searchManufacturer,
				matchChain: !isNaN(searchChain) ? parseInt(searchChain) : searchChain,
				resultProgressVisible: true,
				matchCategoryKey: searchCategory,
				matchStore: searchStore,
			},
			this.debounce(() => {
				if (searchText || searchManufacturer || chainChanged) {
					const chainIndex = searchChain > 0 ? searchChain - 1 : null;
					const chain = chainIndex != null && this.state.chains.length > 0 ? this.state.chains[chainIndex].kod : null;
					Backend.fetchSearchResults(
						searchText,
						searchManufacturer,
						searchCategory,
						matchedProductsIds,
						chain,
						false,
						page || 1,
						-1,
						null,
						null,
						searchStore && searchStore.key,
					).then((result) => {
						if (result.length === 0 && page) {
							this.setState({
								allResultsFetched: true,
								resultProgressVisible: false,
							});
						} else {
							let matchAlternatives = !page ? [] : this.state.matchAlternatives;
							matchAlternatives = [...matchAlternatives, ...result];
							this.setState({
								allResultsFetched: false,
								resultProgressVisible: false,
								matchAlternatives: matchAlternatives,
								page: page || 1,
							});
						}
					});
				} else {
					this.setState({
						allResultsFetched: false,
						resultProgressVisible: false,
					});
				}
			}, this.debounceTime),
		);
	}

	handleProductSelected(product) {
		const originalProducts = this.state.originalProducts;

		if (!originalProducts.some((x) => x.Produkt._key.valueOf() === product.Produkt._key.valueOf())) {
			originalProducts.push(product);
			this.setState({ originalProducts }, () => {
				this.fetchAlternatives(
					this.state.matchAlternativesSearch,
					this.state.matchAlternativesManufacturer,
					this.state.chain,
					this.state.matchStore,
					this.state.matchCategoryKey,
					0,
				);
			});
		}
	}

	handleAiProductSelected(product) {
		const originalProducts = this.state.originalProducts;

		if (!originalProducts.some((x) => x.Produkt._key.valueOf() === product.Produkt._key.valueOf())) {
			product.isAiProduct = true;
			originalProducts.push(product);

			console.log('%c⧭', 'color: #00e600', 'ORGINAL PRODUCTS', originalProducts);

			this.setState({ originalProducts }, () => {
				originalProducts;
			});
		}
	}

	handleProductDeselected(product) {
		let originalProducts = this.state.originalProducts;
		originalProducts = originalProducts.filter((x) => x.Produkt._key !== product.Produkt._key);
		if (!product.isAiProduct) {
			this.setState({ originalProducts }, () => {
				this.fetchAlternatives(
					this.state.matchAlternativesSearch,
					this.state.matchAlternativesManufacturer,
					this.state.matchStore,
					this.state.matchCategoryKey,
					0,
				);
			});
		} else {
			product.isAiProduct = false;
			this.setState({ originalProducts }, () => {
				this.fetchWithSkynet(this.state.matchAiAlternativesSearch, this.state.amount, this.state.price, this.state.em);
			});
		}
	}

	onScrollToBottom = () => {
		if (!this.state.allResultsFetched) {
			this.debounce(this.fetchAlternatives.bind(this), this.debounceTime)(
				this.state.matchAlternativesSearch,
				this.state.matchAlternativesManufacturer,
				this.state.matchChain,
				this.state.matchStore,
				this.state.matchCategoryKey,
				this.state.page + 1,
			);
		}
	};

	onLoadMissingPricesStores() {
		this.setState({
			loadingMissingPricesStores: true,
			missingPricesStores: [],
		});

		Backend.fetchCombinedProductMissingPricesStores(this.state.combineProduct.key).then((stores) => {
			stores.sort((a, b) => (a.namn > b.namn ? 1 : -1));
			const combinedProduct = this.state.combineProduct;
			combinedProduct.missingPriceInStores = stores.length;
			this.setState({
				missingPricesStores: stores,
				combineProduct: combinedProduct,
				loadingMissingPricesStores: false,
			});
		});
	}

	onCancelClick() {
		this.back();
	}

	getCombinedProductGtinsAsArray() {
		if (!this.state.originalProducts || this.state.originalProducts.length === 0) {
			return [];
		}
		return this.state.originalProducts.map((x) => x.Produkt.gtin);
	}

	sendFeedback(gtinList) {
		Backend.sendFeedback(gtinList);
	}

	onSaveClick() {
		this.setState({
			savingProduct: true,
			saveButtonText: 'Sparar...',
		});

		const product = this.state.combineProduct;
		const originalProductIDs = this.state.originalProducts.map((prod) => prod.Produkt._key);
		product.manufacturer = 'Kombinerad';

		const unit = Utils.getUnit(this.state.choosenUnit);
		if (!this.isFieldsValid() && unit !== null && unit.length === 2) {
			this.setState({
				snackbar: 'Rödmarkerade fälten måste vara ifyllda.',
				savingProduct: false,
				saveButtonText: 'Spara',
			});

			return;
		}

		const payload = {
			produktTyp: 3, // combined
			isDuplicate: false,
			namn: product.namn,
			produktinfo: product.produktinfo,
			bildUrl: product.bildUrl,
			// manufacturer: product.manufacturer,
			enhet: 3, // unit[0],
			antal: 1, // product.numberOfItems,
			mangd: 1, // product.amountOfUnits * unit[1],
			minstaDelmangd: 1, // product.incrementValue,
			kategorier: this.state.editCategories,
			originalProductIds: originalProductIDs,
			synlig: product.synlig,
			egenskaper: this.state.selectedMarks,
			visaSomJmfPris: product.visaSomJmfPris,
			publikKombinerad: product.publikKombinerad,
		};

		Backend.saveCombineProduct(this.props.params.id, payload)
			.then((res) => {
				if (res && res.key && !this.props.params.id) {
					// show the newly created id in the url
					window.location.pathname = window.location.pathname + `/${res.key}`;
				}

				this.setState({
					matchChain: 0,
					matchStore: null,
					matchCategoryKey: null,
					savingProduct: false,
					saveButtonText: 'Spara',
				});

				if (payload && this.state.missingPricesStores.length > 0) {
					this.onLoadMissingPricesStores();
				}
			})
			.catch(() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid sparningen av produkten',
					savingProduct: false,
					saveButtonText: 'Spara',
				});
			});
	}

	onDeleteClick() {
		this.setState({
			deletesnackbar: 'Ta bort kombinerad produkt "' + [this.state.combineProduct.namn] + '"?',
		});
		return;
	}

	onCancelDeleteClick() {
		this.setState({ deletesnackbar: '' });
	}

	onConfirmDeleteClick() {
		let promise;
		promise = Backend.deleteCombineProduct(this.props.params.id);
		promise
			.then((result) => {
				this.setState({
					deletesnackbar: '',
					deleteconfirmeddialog: 'Produkten är borttagen.',
					productdeleted: true,
				});
			})
			.catch((error) => {
				this.setState({
					deletesnackbar: '',
					deleteconfirmeddialog: 'Produkten kunde inte tas bort.',
					productdeleted: false,
				});
			});
	}

	onConfirmedDeleteProductClick() {
		this.setState({ deleteconfirmeddialog: '' });
		if (this.state.productdeleted) this.back();
	}

	setProductProperty(property, value) {
		if (property === 'categories') {
			this.setState({
				editCategories: value,
			});
			return;
		}
		const combineProduct = this.state.combineProduct;
		combineProduct[property] = value;

		this.setState({ combineProduct });
	}

	onCheckedboxChangedManufacturer(checked) {
		this.setState({
			allowEmptyManufacturer: checked,
		});
	}

	onCheckedboxChangeVisible(checked) {
		this.setState((state) => ({
			combineProduct: {
				...state.combineProduct,
				synlig: checked,
			},
		}));
	}

	onCheckedboxChangeJmfPris(checked) {
		this.setState((state) => ({
			combineProduct: {
				...state.combineProduct,
				visaSomJmfPris: checked,
			},
		}));
	}

	onCheckedboxChangePublikKombinerad(checked) {
		this.setState((state) => ({
			combineProduct: {
				...state.combineProduct,
				publikKombinerad: checked,
			},
		}));
	}

	onSourcesClick() {
		this.redirect(`/products/${this.props.params.id}/sources`);
	}

	openProductSource(product, store) {
		if (this.hasProductSource(product, store)) {
			let sourceUrl = ProductCombine.chainSourceUrls[store.chain] || '';
			sourceUrl = sourceUrl.replace('{productId}', product.crawlProductId);
			sourceUrl = sourceUrl.replace('{storeId}', store.storeId);

			if (sourceUrl) {
				window.open(sourceUrl, '_blank');
			} else {
				this.setState({
					snackbar: 'Kunde inte öppna källa',
				});
			}
		}
	}

	onCheckedboxChangeEm(checked) {
		this.setState((state) => ({
			combineProduct: {
				...state.combineProduct,
				em: checked,
			},
		}));
	}

	hasProductSource(product, store) {
		return ProductCombine.chainSourceUrls[store.chain] && product.crawlProductId && store.storeId;
	}

	handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			this.fetchWithSkynet(this.state.matchAiAlternativesSearch, this.state.amount, this.state.price, this.state.em);
		}
	};

	onFileChange = (event) => {
		const file = event.target.files.length > 0 ? event.target.files[0] : {};

		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			this.setState({ loading: true });
			const b64 = reader.result.replace(/^data:.+;base64,/, '');
			Backend.uploadImageToProduct(this.state.combineProduct.key, file.name, b64).then((res) => {
				if (res.success) {
					const newProd = {
						...this.state.combineProduct,
						produktBild: res.data.produktBild,
						produktBildThumbnail: res.data.produktBildThumbnail,
					};
					this.setState({
						loading: false,
						combineProduct: newProd,
						uploadImage: null,
						snackbar: 'Uppladdningen lyckades',
						uploadTime: Date.now(),
					});
				} else {
					this.setState({
						loading: false,
						uploadImage: null,
						snackbar: 'Uppladdningen misslyckades!',
					});
				}
			});
		}.bind(this);
		reader.onerror = function (error) {
			console.error('Error: ', error);
		};
	};

	renderProduct() {
		const combineProduct = this.state.combineProduct;

		return (
			<Box className={classes.textfields}>
				{this.props.params.id && (
					<Box style={{ marginBottom: '1em' }}>
						<Box style={{ display: 'flex' }}>
							<Box style={{ display: 'flex', alignItems: 'flex-start' }}>
								<a
									href={combineProduct.produktBild && combineProduct.produktBild.url}
									target="_blank"
									rel="noreferrer"
								>
									<Avatar
										variant="square"
										style={{
											borderRadius: 'none',
											backgroundColor: 'rgba(255,255,255,0)',
											width: '100px',
											height: '100px',
											maxWidth: '80px',
											maxHeight: '80px',
										}}
										onError={(e) => {
											e.target.src =
												'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
										}}
										src={
											combineProduct.produktBildThumbnail &&
											combineProduct.produktBildThumbnail.url + '?' + this.state.uploadTime
										}
										size={-1}
									/>
								</a>
							</Box>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginLeft: '2em',
									alignItems: 'flex-start',
								}}
							>
								<span>
									Källa:{' '}
									{(combineProduct.produktBildThumbnail && combineProduct.produktBildThumbnail.origin) || 'okänd'}
								</span>
								<Box
									style={{
										display: 'flex',
										alignItems: 'baseline',
										gap: '1em',
									}}
								>
									<Box>
										<p>Ladda upp bild:</p>
									</Box>
									<Box>
										<input
											type="file"
											onChange={this.onFileChange}
											value={this.state.uploadImage}
											accept=".gif,.jpg,.jpeg,.png"
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
				<TextField
					// ref="nameField"
					variant={'standard'}
					value={combineProduct.namn || ''}
					onChange={(evt) => this.setProductProperty('namn', evt.target.value)}
					label={'Namn'}
					sx={{ width: '25em' }}
					fullWidth={true}
					error={!this.validateField('namn')}
					helperText={this.validateField('namn') ? '' : 'Namnet får inte vara tomt'}
				/>
				<TextField
					// ref="name2Field"
					variant={'standard'}
					value={combineProduct.produktinfo || ''}
					onChange={(evt) => this.setProductProperty('produktinfo', evt.target.value)}
					label={'Information'}
					sx={{ width: '25em' }}
					fullWidth={true}
				/>
				{!combineProduct.key && (
					<TextField
						value={combineProduct.bildUrl || ''}
						onChange={(evt) => this.setProductProperty('bildUrl', evt.target.value)}
						label={'Bild url'}
						variant={'standard'}
					/>
				)}
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(_, checked) => this.onCheckedboxChangeVisible(checked)}
								checked={combineProduct.synlig}
							/>
						}
						label="Synlig"
					/>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(_, checked) => this.onCheckedboxChangeJmfPris(checked)}
								checked={combineProduct.visaSomJmfPris}
							/>
						}
						label="Visa som Jmf-pris"
					/>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(_, checked) => this.onCheckedboxChangePublikKombinerad(checked)}
								checked={combineProduct.publikKombinerad}
							/>
						}
						label="Publik"
					/>
				</FormGroup>
				<br />
				<Box style={{ display: 'flex', justifyContent: 'start' }}>
					<Box sx={{ width: '25em' }}>
						{this.state.categories.length > 0 && (
							<ProduktGruppChips
								//       ref="categoryField"
								onChange={(val) => this.setProductProperty('categories', val)}
								errorText={this.validateField('categories') ? '' : 'Produktgrupp kan inte lämnas tomt'}
								value={this.state.editCategories}
								categories={this.state.categories}
							></ProduktGruppChips>
						)}
						<h4>Butikprodukter</h4>
						<Box sx={{ maxHeight: '100em' }}>
							<ResultList
								products={this.state.matchedProducts}
								progressVisible={false}
								handleProductSelected={(product) => {
									let mp = this.state.matchedProducts.filter((x) => x.Produkt._key != product.Produkt._key);
									let up = this.state.unmatchedProducts;
									up.push(product.Produkt._key);
									this.setState({ matchedProducts: mp, unmatchedProducts: up });
								}}
								emptyStateText="Inga produkter valda"
							/>
						</Box>
						<Box sx={{ height: '50%', overflow: 'auto' }}>
							<h4>Inkluderade produkter</h4>
							<ResultList
								products={this.state.originalProducts}
								progressVisible={false}
								handleProductSelected={this.handleProductDeselected.bind(this)}
								emptyStateText="Inga produkter valda"
							/>
						</Box>
					</Box>
					<Box
						sx={{
							paddingLeft: '2em',
							display: 'flex',
							width: 'auto',
						}}
					>
						<Box>
							<MarksList
								onChange={(key, selected) => {
									const marks = [...this.state.selectedMarks];
									if (selected && !marks.includes(key)) {
										marks.push(key);
									} else if (!selected && marks.includes(key)) {
										marks.splice(marks.indexOf(key), 1);
									}
									this.setState({ selectedMarks: marks });
								}}
								selectedMarks={this.state.selectedMarks}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	}

	renderMissingPrices() {
		if (!this.props.params.id || !this.state.combineProduct) {
			return <div></div>;
		}
		return (
			<Box sx={{ padding: '0 2em' }}>
				<Card
					variant="outlined"
					className={classes.right}
					sx={{
						overflow: 'scroll',
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'nowrap !important',
						padding: '0 1em',
						height: '60em',
						minWidth: '27em',
					}}
				>
					<div>
						<h4>Saknade butikpriser: {this.state.combineProduct.missingPriceInStores}</h4>
					</div>
					<Box>
						{this.state.missingPricesStores.length === 0 && (
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20em' }}>
								{!this.state.loadingMissingPricesStores && (
									<Button
										variant="outlined"
										size="small"
										onClick={this.onLoadMissingPricesStores.bind(this)}
									>
										Ladda butiker
									</Button>
								)}
								{!!this.state.loadingMissingPricesStores && <CircularProgress />}
							</Box>
						)}
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						{this.state.missingPricesStores.length > 0 && (
							<List key={Math.random()}>
								{this.state.missingPricesStores.map((store) => (
									<ListItemButton
										onClick={() => {
											const cat =
												this.state.editCategories.length > 0 &&
												this.state.categories.filter((x) => x._key == this.state.editCategories[0])[0];
											const catFilter = cat && cat.foralderKey;
											this.fetchAlternatives(
												this.state.combineProduct.namn.replace(' (kombinerad produkt)', ''),
												this.state.matchAlternativesManufacturer,
												this.state.matchChain,
												store,
												catFilter || null,
											);
										}}
									>
										<ListItem>
											<ListItemText
												primary={store.namn}
												secondary={(store.timestamp || '').substring(0, 10) || 'Ingen tidigare datumstämpel'}
											/>
										</ListItem>
									</ListItemButton>
								))}
							</List>
						)}
					</Box>
				</Card>
			</Box>
		);
	}

	renderSkynetMatchedProducts() {
		return (
			<Box className={classes.right}>
				<h4>Skynet sök 🤖</h4>
				<FormControl>
					<TextField
						value={this.state.matchAiAlternativesSearch || ''}
						onKeyDown={this.handleKeyDown}
						onChange={(evt) => {
							if (evt.target.value === '') {
								this.setState({
									aiResults: {
										combined: [],
										similar: [],
									},
									allAiResultsFetched: true,
									aiResultProgressVisible: false,
									matchAiAlternativesSearch: '',
								});
								return;
							}
							this.setState({
								matchAiAlternativesSearch: evt.target.value,
							});
						}}
						label={'Fritext'}
						variant={'standard'}
						fullWidth
					/>

					<TextField
						value={this.state.amount || ''}
						onChange={(evt) =>
							this.setState({
								amount: evt.target.value,
							})
						}
						label={'Mängd'}
						variant={'standard'}
						fullWidth
					/>

					<TextField
						value={this.state.price || ''}
						onChange={(evt) => this.setState({ price: evt.target.value })}
						label={'Pris'}
						variant={'standard'}
						fullWidth
					/>

					<FormControlLabel
						control={
							<Checkbox
								onChange={(_, checked) => this.setState({ em: checked })}
								checked={this.state.em}
							/>
						}
						label="Egna märkesvaror"
					/>
				</FormControl>
				<Button
					sx={{ marginRight: '1em' }}
					variant="contained"
					onClick={(evt) => {
						const { matchAiAlternativesSearch, amount, price, em } = this.state;
						this.fetchWithSkynet(matchAiAlternativesSearch, amount, price, em);
					}}
				>
					Sök
				</Button>

				<h4>Väldigt troliga</h4>
				<Box sx={{ maxHeight: '25em', overflow: 'auto' }}>
					<AiResultList
						products={this.state.aiResults.combined.filter((prod) =>
							this.state.originalProducts.every(
								(originalProd) =>
									originalProd.Produkt._key !== prod.Produkt._key &&
									this.state.combineProduct.key !== prod.Produkt._key,
							),
						)}
						progressVisible={this.state.aiResultProgressVisible}
						handleProductSelected={this.handleAiProductSelected.bind(this)}
						emptyStateText="Inga resultat hittades"
						handleScrollToBottom={null}
					/>
				</Box>

				<h4>Liknande</h4>
				<Box sx={{ maxHeight: '25em', overflow: 'auto' }}>
					<AiResultList
						products={this.state.aiResults.similar.filter((prod) =>
							this.state.originalProducts.every(
								(originalProd) =>
									originalProd.Produkt._key !== prod.Produkt._key &&
									this.state.combineProduct.key !== prod.Produkt._key,
							),
						)}
						progressVisible={this.state.aiResultProgressVisible}
						handleProductSelected={this.handleAiProductSelected.bind(this)}
						emptyStateText="Inga resultat hittades"
						handleScrollToBottom={null}
					/>
				</Box>
			</Box>
		);
	}

	renderMatchingProducts() {
		// if(!this.props.params.crawlproductid) {
		//   return <div />;
		// }

		return (
			<Box className={classes.right}>
				<h4>Sök produkter att kombinera</h4>
				<TextField
					value={this.state.matchAlternativesSearch || ''}
					onChange={(evt) => {
						this.fetchAlternatives(
							evt.target.value,
							this.state.matchAlternativesManufacturer,
							this.state.matchChain,
							this.state.matchStore,
							this.state.matchCategoryKey,
						);
					}}
					label={'Fritext'}
					variant={'standard'}
					fullWidth
				/>

				<TextField
					value={this.state.matchAlternativesManufacturer || ''}
					onChange={(evt) =>
						this.fetchAlternatives(
							this.state.matchAlternativesSearch,
							evt.target.value,
							this.state.matchChain,
							this.state.matchStore,
							this.state.matchCategoryKey,
						)
					}
					label={'Varumärke'}
					variant={'standard'}
				/>

				<Select
					label="Block"
					value={this.state.matchChain}
					onChange={(evt, val) =>
						this.fetchAlternatives(
							this.state.matchAlternativesSearch,
							this.state.matchAlternativesManufacturer,
							val.props.value,
							this.state.matchStore,
							this.state.matchCategoryKey,
						)
					}
					variant="standard"
				>
					<MenuItem
						key={'-1'}
						value={0}
					>
						{'Alla'}
					</MenuItem>
					{this.state.chains.map((chain, index) => (
						<MenuItem
							key={`${index}`}
							value={index + 1}
						>
							{' '}
							{chain.namn}
						</MenuItem>
					))}
				</Select>
				<Box sx={{ paddingTop: '1em' }}>
					{this.state.matchStore && (
						<Chip
							label={this.state.matchStore.namn}
							onDelete={() => {
								this.fetchAlternatives(
									this.state.matchAlternativesSearch,
									this.state.matchAlternativesManufacturer,
									this.state.matchChain,
									null,
									this.state.matchCategoryKey,
								);
							}}
						/>
					)}
					{this.state.matchCategoryKey && (
						<Chip
							label={this.state.categories.find((x) => x._key == this.state.matchCategoryKey).namn}
							onDelete={() => {
								this.fetchAlternatives(
									this.state.matchAlternativesSearch,
									this.state.matchAlternativesManufacturer,
									this.state.matchChain,
									this.state.matchStore,
									null,
								);
							}}
						/>
					)}
				</Box>
				<Box>
					<ResultList
						maxHeight="50em"
						products={this.state.matchAlternatives.filter((prod) =>
							this.state.originalProducts.every(
								(originalProd) =>
									originalProd.Produkt._key !== prod.Produkt._key &&
									this.state.combineProduct.key !== prod.Produkt._key,
							),
						)}
						progressVisible={this.state.resultProgressVisible}
						handleProductSelected={this.handleProductSelected.bind(this)}
						emptyStateText="Inga resultat hittades"
						handleScrollToBottom={this.onScrollToBottom}
					/>
				</Box>
			</Box>
		);
	}

	render() {
		return (
			<ViewContainer
				title={this.state.combineProduct.key ? 'Ändra kombinerad produkt' : 'Ny kombinerad produkt'}
				style={{ overflow: 'none' }}
			>
				<LoadingContainer
					enabled={this.state.loading}
					className={classes.productContainer}
				>
					<Box
						className={classes.top}
						sx={{
							display: 'grid',
							gridGap: '10px',
							gridTemplateColumns: '1fr 1fr 20fr',
						}}
					>
						<Box sx={{ height: '100%' }}>{this.renderProduct()}</Box>
						<Box sx={{ height: '100%' }}>{this.renderMissingPrices()}</Box>
						<Box sx={{ height: '30em', display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' }}>
							<Box sx={{ width: '20%' }}>{this.renderMatchingProducts()}</Box>
							<Box sx={{ width: '20%' }}>{this.renderSkynetMatchedProducts()}</Box>
						</Box>
					</Box>
				</LoadingContainer>

				<Box className="buttons">
					<Button onClick={this.onCancelClick.bind(this)}>Tillbaka</Button>

					<Button
						variant="contained"
						disabled={this.state.loading || this.state.savingProduct || !this.isFieldsValid()}
						onClick={this.onSaveClick.bind(this)}
					>
						{this.state.saveButtonText}
					</Button>

					{!!this.props.params.id && (
						<Button
							variant="contained"
							disabled={false}
							onClick={this.onDeleteClick.bind(this)}
						>
							Ta bort
						</Button>
					)}

					{!!this.props.params.id && (
						<Button
							variant="contained"
							onClick={this.onSourcesClick.bind(this)}
						>
							Butikprodukter
						</Button>
					)}

					<Button
						variant="outlined"
						onClick={(evt) => {
							this.sendFeedback(this.getCombinedProductGtinsAsArray());
						}}
					>
						AI Feedback
					</Button>
				</Box>
				<Snackbar
					open={!!this.state.snackbar}
					message={this.state.snackbar}
					autoHideDuration={3000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
				<Dialog
					open={!!this.state.deletesnackbar}
					onClose={() => this.setState({ deleteSnackbar: '' })}
				>
					<DialogTitle>{this.state.deletesnackbar}</DialogTitle>
					<DialogActions>
						<Button
							variant="contained"
							onClick={this.onCancelDeleteClick.bind(this)}
						>
							Avbryt
						</Button>
						<Button onClick={this.onConfirmDeleteClick.bind(this)}>Ta bort</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={!!this.state.deleteconfirmeddialog}>
					<DialogTitle>{this.state.deleteconfirmeddialog}</DialogTitle>
					<DialogActions>
						<Button
							primary={true}
							onClick={this.onConfirmedDeleteProductClick.bind(this)}
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</ViewContainer>
		);
	}
}

export default withRouter(ProductCombine);
